import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-scroll";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";

const NavComponent = () => {
  const [navbar, setNavbar] = useState(false);

  //navbar breakpoint match

  const [width, setWidth] = useState("");
  const breakpoint = 768;

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  });
  useEffect(() => {
    if (width >= breakpoint) {
      setNavbar(false);
    }
  }, [width]);

  //image GraphQL queries
  const data = useStaticQuery(graphql`
    query {
      logoFluidImage: file(relativePath: { eq: "parduok-auto-logo.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE, formats: [AUTO, WEBP], width: 220)
        }
      }
    }
  `);
  const image = getImage(data.logoFluidImage);
  return (
    <nav className="w-full shadow-2xl">
      <div className="container flex-col justify-between px-4 mx-auto md:items-center md:flex md:px-8">
        <div className="block md:flex items-center justify-between w-full">
          <div>
            <div className="flex items-center justify-between py-3 md:py-5 md:block">
              <div>
                <a href="/">
                  <GatsbyImage image={image} alt="Logo" />
                </a>
              </div>

              <div className="md:hidden">
                <button
                  className="p-2 text-gray-700 rounded-md outline-none focus:border-gray-400 focus:border"
                  onClick={() => setNavbar(!navbar)}
                  aria-label="Navbar button">
                  {navbar ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6"
                      viewBox="0 0 20 20"
                      fill="currentColor">
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}>
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M4 6h16M4 12h16M4 18h16"
                      />
                    </svg>
                  )}
                </button>
              </div>
            </div>
          </div>
          <div>
            <div
              className={`flex-1 justify-self-center pb-3 pt-8 md:block md:pb-0 md:pt-0 ${
                navbar ? "block absolute z-50 bg-white w-full -ml-4 mt-0" : "hidden"
              }`}>
              <ul className="px-4 md:flex text-center">
                <li
                  className={`text-black md:mr-6 hover:text-blue-500 font-semibold cursor-pointer text-xl ${
                    navbar && "pb-3"
                  }`}>
                  <Link
                    onClick={() => setNavbar(false)}
                    activeClass="active"
                    to="formHeader"
                    href="#supirkimas"
                    spy={true}
                    smooth={true}
                    duration={500}>
                    {navbar && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="currentColor"
                        className="mx-auto mb-2"
                        viewBox="0 0 16 16">
                        <path
                          fillRule="evenodd"
                          d="M10.854 8.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708 0z"
                        />
                        <path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1zm3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V5z" />
                      </svg>
                    )}
                    Supirkimas
                  </Link>
                </li>
                <li
                  className={`text-black md:mr-6 hover:text-blue-500 font-semibold cursor-pointer text-xl ${
                    navbar && "pb-3"
                  }`}>
                  <Link
                    onClick={() => setNavbar(false)}
                    activeClass="active"
                    to="aboutHeader"
                    href="#aboutContainer"
                    spy={true}
                    smooth={true}
                    duration={500}>
                    {navbar && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="currentColor"
                        className="mx-auto mb-2"
                        viewBox="0 0 16 16">
                        <path d="M11 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                        <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2v9.255S12 12 8 12s-5 1.755-5 1.755V2a1 1 0 0 1 1-1h5.5v2z" />
                      </svg>
                    )}
                    Apie mus
                  </Link>
                </li>
                <li
                  className={`text-black md:mr-6 hover:text-blue-500 font-semibold cursor-pointer text-xl`}>
                  <Link
                    onClick={() => setNavbar(false)}
                    activeClass="active"
                    to="contactHeader"
                    href="#contact"
                    spy={true}
                    smooth={true}
                    duration={500}>
                    {navbar && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="currentColor"
                        className="mx-auto mb-2"
                        viewBox="0 0 16 16">
                        <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                        <path
                          fillRule="evenodd"
                          d="M12.5 1a.5.5 0 0 1 .5.5V3h1.5a.5.5 0 0 1 0 1H13v1.5a.5.5 0 0 1-1 0V4h-1.5a.5.5 0 0 1 0-1H12V1.5a.5.5 0 0 1 .5-.5z"
                        />
                      </svg>
                    )}
                    Susisiekite
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <p class="text-xl sm:text-2xl pt-0 pb-3 flex gap-3 justify-center flex flex-col md:block">
          <a
            href="tel:+37064119119"
            target="_blank"
            rel="noreferrer"
            class="text-blue-500 font-semibold hover:text-blue-500">
            +370 64 119 119
          </a>
          <span className="flex flex-col md:inline-block md:ms-3">
            Rašykite:&nbsp;
            <a
              href="mailto:info@superkameautomobilius.lt"
              target="_blank"
              rel="noreferrer"
              class="text-blue-500 font-semibold hover:text-blue-500 break-words">
              info@superkameautomobilius.lt
            </a>
          </span>
        </p>
      </div>
    </nav>
  );
};

export default NavComponent;
