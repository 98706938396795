import React from "react";
import { useState } from "react";
import FormModal from "./FormModal";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

const Form = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [city, setCity] = useState(null);

  const [info, setInfo] = useState({ infoText: "", success: false });
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inputDisabled, setInputDisabled] = useState(true);
  const [markInputValue, setMarkInputValue] = useState("");

  const handleMarkInputChange = (event) => {
    const value = event.target.value;
    setMarkInputValue(value);
    if (value.trim() === "") {
      setInputDisabled(true);
      document.querySelector('input[name="Modelis"]').value = "";
    } else {
      setInputDisabled(false);
    }
  };

  //ajax form submission
  function formSubmit(e) {
    e.preventDefault();
    setDisabled(true);
    setLoading(true);
    const formData = new FormData();
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "FormSubmit",
    });
    formData.append("Markė", markInputValue);
    formData.append("Modelis", document.querySelector('input[name="Modelis"]').value);

    formData.append("Pageidaujama kaina", document.querySelector('input[name="Kaina"]').value);
    formData.append("Miestas", document.querySelector('input[name="Miestas"]').value);
    formData.append("Telefono numeris", document.querySelector('input[name="Telefonas"]').value);
    fetch(`${process.env.GATSBY_API_URL}`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        setLoading(false);
        if (response.redirected === false) {
          setInfo({
            infoText: "Įvyko nenumatyta klaida. Bandykite vėliau",
            success: false,
          });
        } else {
          e.target.reset();
          setMarkInputValue("");
          setInfo({
            infoText: "Užklausos forma išsiųsta sėkmingai",
            success: true,
          });
        }
        setIsModalOpen(true);
      })
      .catch((error) => {
        console.log(error.message);
        setLoading(false);

        setInfo({
          infoText: "Įvyko nenumatyta klaida. Bandykite vėliau",
          success: false,
        });
        setIsModalOpen(true);
      });
  }

  return (
    <>
      <form
        onSubmit={(e) => formSubmit(e)}
        id="autoForm"
        action=""
        className="w-full mx-auto px-4 pb-8 pt-0 md:pt-8">
        <div className="flex flex-wrap -mx-3">
          <div className="w-full px-3 mb-6 md:mb-4">
            <label className="block tracking-wide  text-xs font-bold mb-2" htmlFor="grid-marke">
              Automobilio markė *
            </label>
            <input
              className="appearance-none block w-full  border rounded py-3 px-4 mb-1 leading-tight focus:outline-none bg-white focus:border-gray-500"
              id="grid-marke"
              onChange={handleMarkInputChange}
              name="Marke"
              type="text"
              placeholder="Markė"
              required
              maxLength="250"
            />
          </div>
          <div className="w-full px-3 mb-6 md:mb-4">
            <label className="block tracking-wide  text-xs font-bold mb-2" htmlFor="grid-marke">
              Automobilio modelis *
            </label>
            <input
              className="appearance-none block w-full  border rounded py-3 px-4 mb-1 leading-tight focus:outline-none bg-white focus:border-gray-500"
              id="grid-modelis"
              name="Modelis"
              type="text"
              placeholder="Modelis"
              required
              maxLength="250"
              disabled={inputDisabled}
            />
          </div>

          <div className="w-full px-3 mb-6 md:mb-4">
            <label className="block tracking-wide  text-xs font-bold mb-2" htmlFor="grid-city">
              Miestas *
            </label>
            <Autocomplete
              disablePortal
              freeSolo
              id="grid-city"
              options={cities}
              value={city}
              onChange={(e) => setCity(e.target.textContent)}
              renderInput={(params) => (
                <TextField
                  className="appearance-none block w-full  border rounded p-0 mb-1 leading-tight focus:outline-none bg-white focus:border-gray-500"
                  {...params}
                  placeholder="Miestas"
                  name="Miestas"
                  required
                />
              )}
            />
          </div>

          <div className="w-full  px-3 mb-6 md:mb-4">
            <label className="block tracking-wide  text-xs font-bold mb-2" htmlFor="grid-phone">
              Telefono numeris *
            </label>
            <input
              className="appearance-none block w-full  border rounded py-3 px-4 mb-1 leading-tight focus:outline-none bg-white focus:border-gray-500"
              id="grid-phone"
              name="Telefonas"
              type="tel"
              placeholder="Telefono numeris"
              required
              maxLength="250"
            />
          </div>

          <div className="w-full px-3 mb-6 md:mb-4">
            <label className="block tracking-wide  text-xs font-bold mb-2" htmlFor="grid-price">
              Pageidaujama kaina (eur) *
            </label>
            <input
              className="appearance-none block w-full  border rounded py-3 px-4 mb-1 leading-tight focus:outline-none bg-white focus:border-gray-500"
              id="grid-price"
              name="Kaina"
              type="number"
              min="0"
              placeholder="Pageidaujama kaina"
              required
              maxLength="250"
            />
          </div>
        </div>
        <button
          className="rounded-3xl transition-all bg-blue-500 border-2 border-bg-blue text-white uppercase hover:bg-black w-full font-bold px-4 py-3 focus:outline-none focus:shadow-outline"
          type="submit"
          disabled={disabled}>
          {loading ? (
            <span>
              <svg
                className="animate-spin inline-block -ml-1 mr-3 h-5 w-5 text-black"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24">
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              Siunčiama
            </span>
          ) : (
            "Pateikti užklausą"
          )}
        </button>
      </form>
      {isModalOpen ? (
        <FormModal info={info} setIsModalOpen={setIsModalOpen} setDisabled={setDisabled} />
      ) : (
        ""
      )}
    </>
  );
};

export default Form;

const cities = [
  "Vilnius",
  "Kaunas",
  "Klaipėda",
  "Šiauliai",
  "Panevėžys",
  "Alytus",
  "Marijampolė",
  "Mažeikiai",
  "Jonava",
  "Utena",
  "Kėdainiai",
  "Telšiai",
  "Visaginas",
  "Tauragė",
  "Ukmergė",
  "Plungė",
  "Kretinga",
  "Palanga",
  "Radviliškis",
  "Druskininkai",
  "Gargždai",
  "Rokiškis",
  "Biržai",
  "Šilutė",
  "Jurbarkas",
  "Trakai",
  "Raseiniai",
  "Elektrėnai",
  "Naujoji Akmenė",
  "Lentvaris",
  "Grigiškės",
  "Garliava",
  "Vilkaviškis",
  "Kuršėnai",
  "Nida",
  "Kupiškis",
  "Zarasai",
  "Molėtai",
  "Kaišiadorys",
  "Šalčininkai",
  "Žiežmariai",
  "Prienai",
  "Joniškis",
  "Varėna",
  "Kelmė",
  "Anykščiai",
  "Eiguliai",
  "Pabradė",
  "Pakruojis",
  "Vievis",
  "Rietavas",
  "Silale",
  "Ežerėlis",
  "Simnas",
  "Šakiai",
  "Gelgaudiškis",
  "Rūdiškės",
  "Virbalis",
  "Salantai",
  "Veisiejai",
  "Subačius",
  "Daugai",
  "Birštonas",
  "Eišiškės",
  "Obeliai",
  "Pagėgiai",
  "Žagarė",
  "Nemenčinė",
  "Kudirkos Naumiestis",
  "Kybartai",
  "Kavarskas",
  "Jieznas",
  "Vabalninkas",
  "Smalininkai",
  "Panemunėlis",
  "Joniškėlis",
  "Vandžiogala",
  "Ramygala",
  "Dusetos",
  "Kapčiamiestis",
  "Vilkija",
  "Pandėlys",
  "Linkuva",
];
