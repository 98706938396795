import * as React from "react";
import NavComponent from "../components/NavComponent";
import FooterComponent from "../components/FooterComponent";
import SEO from "../components/SEO";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";
import Form from "./elements/Form";
import { Link } from "react-scroll";

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      bannerFluidImage: file(relativePath: { eq: "parduok_banner.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE, formats: [AUTO, WEBP])
        }
      }
      purchaseFluidImage: file(relativePath: { eq: "car-purchase.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE, formats: [AUTO, WEBP])
        }
      }
      contactFluidImage: file(relativePath: { eq: "superkame_automobilius.jpeg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE, formats: [AUTO, WEBP])
        }
      }
      carColumnFluid: file(relativePath: { eq: "red-car.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE, formats: [AUTO, WEBP])
        }
      }
    }
  `);
  const image = getImage(data.bannerFluidImage);
  const purchaseImage = getImage(data.purchaseFluidImage);
  const contactImage = getImage(data.contactFluidImage);

  return (
    <main className="font-body bg-white">
      <NavComponent />
      <div
        className="text-center pt-0 pb-16 lg:pt-16 px-4 relative bg-[#F3F4F6]"
        style={{ borderRadius: "0px 0px 15px 15px" }}>
        <div className="max-w-lg mx-auto">
          <Link
            className="block border-blue-500 transition ease-in-out duration-300 bg-blue-500 hover:bg-black text-white font-bold py-3 px-6 mt-10 md:mt-6 rounded-full"
            to="formHeader"
            href="#supirkimas"
            spy={true}
            smooth={true}
            duration={500}>
            {" "}
            GAUTI PASIŪLYMĄ
          </Link>
          <Link
            className="block border transition ease-in-out duration-300 bg-blue-500 hover:bg-black text-white font-bold py-3 px-6 mt-3 md:mt-6 rounded-full"
            to="aboutHeader"
            href="#aboutContainer"
            spy={true}
            smooth={true}
            duration={500}>
            {" "}
            SUŽINOTI DAUGIAU
          </Link>
        </div>

        <h1 className="text-xl md:text-4xl font-bold mt-8 mb-4">
          PARDUOK AUTOMOBILĮ UŽ GERIAUSIĄ KAINĄ!
        </h1>
        <p className="mb-4 text-md">Automobilio pardavimas tą pačią dieną</p>
        <div className="container mx-auto items-center grid grid-cols-1 gap-4 lg:grid-cols-2">
          <GatsbyImage image={image} alt="Parduok baneris" />
          <div className="mx-auto max-w-lg">
            <ul className="custom-list">
              <li className="pl-6 md:pl-8 text-left text-base md:text-2xl mb-3 font-semibold">
                ĮVERTINAME VISAS APLINKYBES
              </li>
              <li className="pl-6 md:pl-8 text-left text-base md:text-2xl mb-3 font-semibold">
                DUODAME GERIAUSIĄ PASIŪLYMĄ
              </li>
              <li className="pl-6 md:pl-8 text-left text-base md:text-2xl mb-3 font-semibold">
                SUTVARKOME VISUS FORMALUMUS
              </li>
              <li className="pl-6 md:pl-8 text-left text-base md:text-2xl mb-3 font-semibold">
                TAUPOME JŪSŲ LAIKĄ IR PINIGUS
              </li>
              <li className="pl-6 md:pl-8 text-left text-base md:text-2xl font-semibold">
                UŽTIKRINAME PASLAUGOS KOKYBĘ
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="container mx-auto pt-4 bg-white px-4">
        <h2 id="formHeader" className="text-center px-4 text-2xl md:text-4xl mt-6 font-bold">
          PILDYK UŽKLAUSĄ!
        </h2>
        <p className="text-center pb-6 pt-4">
          Susisieksime kuo greičiau ir suderinsime geriausią laiką bei aplinkybes automobilio
          pardavimui.
        </p>
      </div>
      <div className="container mb-12 mx-auto grid grid-cols-1 lg:grid-cols-2 bg-white">
        <Form />
        <div className="hidden lg:block">
          <GatsbyImage
            image={purchaseImage}
            alt="Automobilis pardavimui"
            className="min-h-full lg:max-h-[500px] object-cover"
          />
        </div>
      </div>

      <h2 id="aboutHeader" className="text-2xl md:text-4xl px-4 font-bold text-center mb-12 mt-12">
        PARDAVIMAS PER 3 ŽINGSNIUS
      </h2>
      <div className="container px-4 md:px-0 mx-auto mb-8 md:mb-16 grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3">
        <div className="bg-white shadow-xl border rounded-lg p-4">
          <div className="flex gap-6">
            <div>
              <span className="w-8 h-8 bg-blue-500 inline-block text-center text-2xl text-white font-bold leading-8">
                1
              </span>
            </div>
            <div>
              <p className="mb-3 text-xl font-[600] leading-[26px]">Pateikite užklausą</p>
              <span>
                Užpildykite puslapio apačioje esančią formą. Jums tai užtruks tik kelias minutes, o
                mes susisieksime su jumis kuo greičiau. Esame pasiruošę automobilį supirkti vos per
                pusvalandį, jums tinkamu laiku.
              </span>
            </div>
          </div>
        </div>
        <div className="bg-white shadow-xl border rounded-lg p-4">
          <div className="flex gap-6">
            <div>
              <span className="w-8 h-8 bg-blue-500 inline-block text-center text-2xl text-white font-bold leading-8">
                2
              </span>
            </div>
            <div>
              <p className="mb-3 text-xl font-[600] leading-[26px]">Automobilio įvertinimas</p>
              <span>
                Patikimas ir profesionalus automobilių supirkėjas atvykęs paprasčiausiai apžiūrės ir
                įvertins jūsų automobilio būklę neversdamas jūsų tampytis po servisus ir gaišti savo
                laiko.
              </span>
            </div>
          </div>
        </div>
        <div className="bg-white shadow-xl border rounded-lg p-4">
          <div className="flex gap-6">
            <div>
              <span className="w-8 h-8 bg-blue-500 inline-block text-center text-2xl text-white font-bold leading-8">
                3
              </span>
            </div>
            <div>
              <p className="mb-3 text-xl font-[600] leading-[26px]">
                Atsiskaitymas ir išregistravimas
              </p>
              <span>
                Supirkėjas pasiūlys už automobilį geriausią kainą. Jums sutikus, mes padėsime su
                automobilio išregistravimu ir transportavimu. Atsiskaitysime iš karto!
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gray-100 pb-6">
        <h2 className="text-2xl md:text-4xl px-4 font-bold text-center mb-8 pt-12">
          POZITYVŪS KLIENTŲ ATSILIEPIMAI APIE MUS
        </h2>

        <div className="container px-4 md:px-0 mx-auto my-8 grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3">
          <div className="py-4 px-4 bg-white shadow-lg rounded-lg ">
            <div>
              <div className="flex items-center">
                <span className="d-inline bg-gray-300 p-4 rounded-full">T.D.</span>
              </div>
              <p className="mt-2 text-gray-600">
                Sveiki, Galiu palikt tik geriausią atsiliepimą apie parduok-auto paslaugas.
                Supirkimo specialistas operatyviai sureagavo į mano užklausą ir atvykęs pilnai
                pasirūpino pardavimo procesu.Už automobilį gavau net šiek tiek daugiau nei
                tikėjausi, tad jaučiuosi labai teisingai įvertintas ir nusikratęs nemalonios naštos.
              </p>
            </div>
            <div className="flex flex-col justify-end mt-4">
              <p className="text-xl block font-bold text-black">Tomas Dainauskis</p>
              <p className="text-xl font-bold text-black">2023.08.11</p>
            </div>
          </div>

          <div className="py-4 px-4 bg-white shadow-lg rounded-lg ">
            <div>
              <div className="flex items-center">
                <span className="d-inline bg-gray-300 p-4 rounded-full">R.S.</span>
              </div>
              <p className="mt-2 text-gray-600">
                Labai gera paslauga, rekomenduoju. Formalumus sutvarkėme greitai, atvykęs
                specialistas buvo pasiruošęs ir padėjo su automobilio išregistravimu. Už 16 metų
                senumo skodą su dideliais defektais, gavau labai gerą pasiūlymą. Sekantį automobilį
                matyt parduosiu taip pat čia.
              </p>
            </div>
            <div className="flex flex-col justify-end mt-4">
              <p className="text-xl block font-bold text-black">Rimvydas Saucevičius</p>
              <p className="text-xl font-bold text-black">2023.08.22</p>
            </div>
          </div>
          <div className="py-4 px-4 bg-white shadow-lg rounded-lg ">
            <div>
              <div className="flex items-center">
                <span className="d-inline bg-gray-300 p-4 rounded-full">E.M.</span>
              </div>
              <p className="mt-2 text-gray-600">
                Paslauga labai tenkino ir tuo pačiu gavau rinkai konkurencingą išmoką. Yra tekę
                anskčiau panašiomis aplinkybėmis pardavinėti automobilį, tai teko “pasimauti” ir ant
                labai sudėtingų pirkėjų. Džiaugiuosi radęs parduok-auto, viskas buvo labai paprasta,
                specialistai patys pasirūpino viskuo.
              </p>
            </div>
            <div className="flex flex-col justify-end mt-4">
              <p className="text-xl block font-bold text-black">Edvardas Makarovas</p>
              <p className="text-xl font-bold text-black">2023.08.12</p>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gray-100">
        <h2
          id="contactHeader"
          className="text-2xl md:text-4xl px-4 font-bold text-center mb-8 pt-12">
          SUSISIEKITE
        </h2>
        <GatsbyImage
          image={contactImage}
          alt="Automobilis pardavimui"
          className="max-h-[300px] min-w-full object-cover"
        />
        <FooterComponent />
      </div>
    </main>
  );
};

export const Head = () => <SEO />;

export default IndexPage;
